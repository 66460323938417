import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Button
} from '@blueprintjs/core';
import axios from 'axios';

export const Processar_db = observer(({store}) => {

    const [listaId, setListaId] = useState('');
    const [iframeVisible, setIframeVisible] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('http://localhost:5173');

    const abrirIframe = () => {
        setIframeVisible(true);
    };

    const fecharIframe = () => {
        setIframeVisible(false);
    };

    async function consultarBancoDeDadosPolotno(listaId) {
        try {
            const response = await axios.get(`http://localhost:3000/lista/polotno/${listaId}`);
            return response.data;
        } catch (error) {
            console.error('Erro ao consultar o banco de dados:', error);
        }
    }

    function encontrarValores(store) {
        const regex = /%\(([^)]+)\)%/g;
        const valoresEncontrados = [];

        store.pages[0].children.forEach((elemento) => {
            if (elemento.name) {
                let match;
                regex.lastIndex = 0;
                while ((match = regex.exec(elemento.name)) !== null) {
                    const [prefixo, id, coluna] = match[1].split(';');
                    valoresEncontrados.push({
                        prefixo,
                        id,
                        coluna,
                        elemento
                    });
                }
            }
        });

        return valoresEncontrados;
    }

    function organizarPorItem(valoresEncontrados) {
        const itens = {};

        valoresEncontrados.forEach((valor) => {
            const {id, prefixo, coluna, elemento} = valor;
            if (!itens[id]) {
                itens[id] = [];
            }
            itens[id].push({prefixo, coluna, elemento});
        });

        return itens;
    }

    async function atualizarCanvas(store, listaId) {
        const valoresEncontrados = encontrarValores(store);
        const itens = organizarPorItem(valoresEncontrados);
        debugger;
        try {
            debugger
            const lista = await consultarBancoDeDadosPolotno(listaId);
            const produtosConvertidos = lista.map(produto => {
                // Garantir que todos os valores são convertidos para string
                const produtoConvertido = {};
                for (let key in produto) {
                    if (produto.hasOwnProperty(key)) {
                        produtoConvertido[key] = String(produto[key]);
                    }
                }
                return produtoConvertido;
            });
            Object.entries(itens).forEach(([id, valores], index) => {
                const dados = produtosConvertidos[index];

                valores.forEach((valor) => {
                    if (valor.prefixo === 'IMG') {
                        valor.elemento.set({
                            src: dados[valor.coluna],
                            cropX: 0,
                            cropY: 0
                            // cropWidth: valor.elemento.width(),
                            // cropHeight: valor.elemento.height()
                        });
                    } else if (valor.prefixo === 'TXT') {
                        valor.elemento.set({
                            text: dados[valor.coluna]
                        });
                    }
                });
            });
        } catch (error) {
            console.error('Erro ao consultar o banco de dados:', error);
        }
    }

    return (
        <div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <label style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                Id da Lista:
            <input
                style={{marginLeft: '10px'}}
                type="number"
                value={listaId}
                onChange={(e) => setListaId(Number(e.target.value))}
                placeholder="Insira o ID da lista"
            />
            </label>
            <Button
                style={{marginLeft: '10px', marginRight: '5px'}}
                fill
                intent="primary"
                onClick={async () => {
                    await atualizarCanvas(store, listaId)
                }}
            >
                Processar
            </Button>

            <Button
                style={{marginLeft: '10px'}}
                fill
                intent="success"
                onClick={abrirIframe}
            >
                Abrir Lista
            </Button>
        </div>
        <div>
            {iframeVisible && (
                <div style={styles.overlay}>
                    <div style={styles.iframeContainer}>
                        <button style={styles.closeButton} onClick={fecharIframe}>
                            Fechar
                        </button>
                        <iframe
                            src={iframeUrl}
                            title="Iframe Exemplo"
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        />
                    </div>
                </div>
            )}
        </div>
        </div>
    );
});

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    iframeContainer: {
        width: '80%',
        height: '80%',
        backgroundColor: 'white',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
    }
};
