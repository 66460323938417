import React from 'react';
import {observer} from 'mobx-react-lite';
import {
    Button
} from '@blueprintjs/core';
import axios from 'axios';

export const Processar_db = observer(({store}) => {

    async function consultarBancoDeDadosPolotno(limite) {
        try {
            const config = {
                host: 'localhost',
                database: 'database',
                table: 'tb_produtos',
                user: 'database',
                password: 'database',
                limite: limite
            };

            const response = await axios.post('https://canva.v4tech.com.br/v4middle/consulta', config);
            return response.data;
        } catch (error) {
            console.error('Erro ao consultar o banco de dados:', error);
        }
    }

    function encontrarValores(store) {
        const regex = /%\(([^)]+)\)%/g;
        const valoresEncontrados = [];

        store.pages[0].children.forEach((elemento) => {
            if (elemento.name) {
                let match;
                regex.lastIndex = 0;
                while ((match = regex.exec(elemento.name)) !== null) {
                    const [prefixo, id, coluna] = match[1].split('_');
                    valoresEncontrados.push({
                        prefixo,
                        id,
                        coluna,
                        elemento
                    });
                }
            }
        });

        return valoresEncontrados;
    }

    function organizarPorItem(valoresEncontrados) {
        const itens = {};

        valoresEncontrados.forEach((valor) => {
            const { id, prefixo, coluna, elemento } = valor;
            if (!itens[id]) {
                itens[id] = [];
            }
            itens[id].push({prefixo, coluna, elemento});
        });

        return itens;
    }

    async function atualizarCanvas(store) {
        const valoresEncontrados = encontrarValores(store);
        const itens = organizarPorItem(valoresEncontrados);
        debugger;
        try {
            const dadosBanco = await consultarBancoDeDadosPolotno(Object.keys(itens).length);
            Object.entries(itens).forEach(([id, valores], index) => {
                const dados = dadosBanco[index];

                valores.forEach((valor) => {
                    if (valor.prefixo === 'IMG') {
                        valor.elemento.set({
                            src: dados[valor.coluna],
                            cropX: 0,
                            cropY: 0,
                            cropWidth: 1,
                            cropHeight: 1
                        });
                    } else if (valor.prefixo === 'TXT') {
                        valor.elemento.set({
                            text: dados[valor.coluna]
                        });
                    }
                });
            });
        } catch (error) {
            console.error('Erro ao consultar o banco de dados:', error);
        }
    }

    return (
        <Button
            fill
            intent="primary"
            onClick={async () => {
                await atualizarCanvas(store)
            }}
        >
            Processar Variáveis
        </Button>
    );
});
